import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Messages from '../components/settings/instagram/Messsages';
import SelectionComponent, {
  SelectionComponentItem,
} from '../components/settings/instagram/SelectionComponent';
import { BRAND_THREADS, EXTERNAL_USERS } from '../graphql/queries';
import { UserContext } from '../providers/UserProvider';
import { Thread, ThreadEdge } from '../__generated__/graphql';
import { Spinner } from '@kalecard/common';

export default function InstagramMessagesPage() {
  const params = useParams();
  const externalUserId = params['id'];
  const { brandId } = useContext(UserContext);
  const [edges, setEdges] = useState<ThreadEdge[]>([]);
  const [thread, setThread] = useState<Thread>(null);
  const history = useHistory();

  const { data, loading } = useQuery(EXTERNAL_USERS, {
    variables: { brandId },
  });

  const [brandThreads, { loading: loadingThreads }] = useLazyQuery(
    BRAND_THREADS,
    {
      onCompleted: (data) => {
        const newEdges = [...edges, ...data.brandThreads.edges];
        setEdges(newEdges);
        setThread(
          newEdges.find((edge) => edge.node.id === params['mid'])?.node
        );
      },
    }
  );

  useEffect(() => {
    const selectedExternalUser = data?.externalUsers?.find(
      (externalUser) => externalUser.id === externalUserId
    );
    if (selectedExternalUser) {
      brandThreads({
        variables: {
          first: 20,
          brandId: brandId,
          facebookAccountId: 'not-used-anymore',
          instagramId: selectedExternalUser.externalId,
        },
      });
    }
  }, [data, brandId]);

  function formatAsSelectionItem(
    edges: ThreadEdge[]
  ): SelectionComponentItem[] {
    return edges.map((edge) => {
      return {
        id: edge.node.id,
        name: edge.node.participants[1].username,
      };
    });
  }

  function handleThreadSelected(item: SelectionComponentItem) {
    history.push(`/instagram/${externalUserId}/messages/${item.id}`);
    setThread(edges.find((edge) => edge.node.id === item.id)?.node);
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Instagram Messages
        </h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {loading || loadingThreads ? <Spinner size={'h-5 w-5'} /> : null}
        {!loading && !loadingThreads ? (
          <div className="">
            <SelectionComponent
              list={formatAsSelectionItem(edges)}
              selectedId={params['mid']}
              onSelect={handleThreadSelected}
              defaultTitle="Choose a thread"
            />
            {thread && <Messages thread={thread} />}
          </div>
        ) : null}
      </div>
    </>
  );
}
