import { Description, Field, Label } from '../../catalyst/fieldset';
import KaleBrandsHelmet from '../../utils/KaleBrandsHelmet';
import { useLocation, useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { BRAND_OAUTH } from '../../../graphql/mutations';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../providers/UserProvider';
import { BRANDS_URL, Spinner } from '@kalecard/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ArrowUpRightIcon } from '@heroicons/react/16/solid';
import { Button } from '../../catalyst/button';
import { UserIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { EXTERNAL_USERS } from '../../../graphql/queries';
import {
  BrandOauthMutation,
  ExternalUser,
} from '../../../__generated__/graphql';

export const INSTAGRAM_SCOPES = new Set<string>();
// Setting up access to instagram
INSTAGRAM_SCOPES.add('instagram_business_basic');
INSTAGRAM_SCOPES.add('instagram_business_manage_messages');
INSTAGRAM_SCOPES.add('instagram_business_manage_insights');

const REDIRECT_URI =
  `${BRANDS_URL}/settings/instagram` +
  '&response_type=code&scope=' +
  Array.from(INSTAGRAM_SCOPES).join('%2C');

const OAUTH_URL =
  'https://www.instagram.com/oauth/authorize' +
  '?enable_fb_login=0' +
  '&force_authentication=1' +
  '&client_id=590258807226983' +
  `&redirect_uri=${REDIRECT_URI}`;

export default function InstagramSettingsPage() {
  const { search, pathname } = useLocation();
  const { brandId } = useContext(UserContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useQuery(EXTERNAL_USERS, {
    variables: { brandId },
  });

  const [brandOauth] = useMutation(BRAND_OAUTH, {
    update(cache, { data: { brandOauth } }: { data: BrandOauthMutation }) {
      // Read the existing list of external users from the cache
      const existingExternalUsers = cache.readQuery({
        query: EXTERNAL_USERS,
        variables: { brandId },
      });
      const newExternalUser = brandOauth[0];
      console.log(newExternalUser, existingExternalUsers);

      // Update the cache by adding the new external user
      const alreadyExists = existingExternalUsers?.externalUsers.find(
        (externalUser: ExternalUser) => externalUser.id === newExternalUser.id
      );
      console.log(alreadyExists);

      if (!alreadyExists) {
        const newExternalUsers = [];
        if (existingExternalUsers) {
          newExternalUsers.push(...existingExternalUsers.externalUsers);
        }
        newExternalUsers.push(newExternalUser);
        console.log(newExternalUsers);
        cache.writeQuery({
          query: EXTERNAL_USERS,
          variables: { brandId },
          data: {
            externalUsers: newExternalUsers,
          },
        });
      }
    },
  });

  const connectInstagram = async (code: string) => {
    setIsLoading(true);
    const result = await brandOauth({
      variables: {
        brandId,
        code,
        scopes: Array.from(INSTAGRAM_SCOPES).join(','),
        source: 'BRAND_DASHBOARD',
        externalPlatform: 'INSTAGRAM',
      },
    });
    setIsLoading(false);
    // console.log(result);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('code')) {
      console.log('here');
      connectInstagram(params.get('code'));
      // Remove the parameters from the URL
      const newUrl = `${pathname}`;
      history.replace(newUrl);
    }
  }, [search]);

  return (
    <>
      <KaleBrandsHelmet pageName="Instagram Settings" />
      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <Field>
                <Label>Instagram Settings</Label>
                <Description>
                  Details about your Instagram connected accounts.
                </Description>
                {data?.externalUsers.length ? (
                  <ConnectInstagramButton text={'Connect more'} />
                ) : null}
              </Field>
              <div className="mt-1 flex w-full flex-col sm:col-span-2 sm:mt-0">
                <div className={'flex w-full'}>
                  {data?.externalUsers.length ? (
                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                      {data?.externalUsers.map((externalUser: ExternalUser) => (
                        <ExternalUserComponent
                          key={externalUser.id}
                          externalUser={externalUser}
                        />
                      ))}
                    </ul>
                  ) : (
                    <ConnectInstagramButton />
                  )}
                </div>
                {isLoading ? (
                  <div className={'pt-5'}>
                    <Spinner size={'h-5 w-5'} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ConnectInstagramButton({ text }: { text?: string }) {
  return (
    <div className="flex flex-col space-y-4 divide-y divide-gray-200">
      <div className="pt-2">
        <h3 className="pb-2">
          <Button
            href={OAUTH_URL}
            color={'white'}
          >
            <FontAwesomeIcon
              icon={faInstagram as IconProp}
              size="xl"
            />
            {text ?? 'Connect Instagram'}
            <ArrowUpRightIcon />
          </Button>
        </h3>
      </div>
    </div>
  );
}

function ExternalUserComponent({
  externalUser,
}: {
  externalUser: ExternalUser;
}) {
  return (
    <li className="flex items-center py-3 pl-3 pr-4 text-sm">
      <div>
        <div className="flex items-center">
          {externalUser.pictureUrl ? (
            <img
              src={externalUser.pictureUrl}
              className="h-6 w-6 rounded-full"
              onError={() => {}}
              alt={externalUser.username}
            />
          ) : (
            <>
              <UserIcon className="h-6 w-6 rounded-full" />
            </>
          )}
          <span className="ml-2 truncate">
            <a
              className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
              target="_blank"
              href={`https://www.instagram.com/${externalUser.username}`}
            >
              {externalUser.username}
            </a>
          </span>
        </div>
        <div className="mt-2 flex space-x-2 text-xs">
          <Link
            className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
            to={`/external-user/${externalUser.id}/post`}
          >
            View media
          </Link>
          <div className={'border border-kale-green-500'} />
          <Link
            className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
            to={`/instagram/${externalUser.id}/messages`}
          >
            View messages
          </Link>
        </div>
      </div>
    </li>
  );
}
