import { useRef, useState } from 'react';
import { useIsVisible } from 'react-is-visible';

interface MediaComponentProps {
  url: string;
  mediaType: string;
  shouldChangeColor?: boolean;
  showControls?: boolean;
  width?: number;
  height?: number;
  altLinkComponent?: JSX.Element;
}

export default function MediaComponent({
  url,
  mediaType,
  shouldChangeColor,
  showControls = true,
  width = 266,
  height = 473,
  altLinkComponent,
}: MediaComponentProps) {
  const mediaRef = useRef(null);
  const isVisible = useIsVisible(mediaRef);
  const [showAltLinkInsideVideo, setShowAltLinkInsideVideo] = useState(false);
  return (
    <div
      className="flex justify-center"
      ref={mediaRef}
    >
      <div
        className={`flex w-full items-center justify-center rounded-2xl p-2 ${
          shouldChangeColor === true
            ? 'bg-kale-orange-200'
            : 'bg-kale-green-200'
        }`}
      >
        <div
          className={
            'h-[' +
            height +
            'px] w-[' +
            width +
            'px] relative items-center justify-center rounded-2xl'
          }
        >
          {mediaType === 'IMAGE' && (
            <img
              key={url}
              src={url}
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              onError={(e) =>
                ((e.target as HTMLImageElement).style.display = 'none')
              }
              className="flex:1 h-full w-full rounded-2xl object-cover p-2"
            ></img>
          )}
          {mediaType === 'VIDEO' && isVisible && (
            <video
              controls={showControls === true}
              key={url}
              autoPlay={false}
              loop={true}
              muted={true}
              playsInline={true}
              src={url}
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture={true}
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              onError={(e) => {
                (e.target as HTMLVideoElement).style.display = 'none';
                setShowAltLinkInsideVideo(true);
              }}
              className="h-full w-full flex-1 rounded-2xl object-contain"
            ></video>
          )}
          {showAltLinkInsideVideo && !!altLinkComponent && (
            <div className="absolute inset-0 flex h-full items-center justify-center">
              {altLinkComponent}
            </div>
          )}
          {!!altLinkComponent && (
            <div className="mt-4 flex h-full items-center justify-center">
              {altLinkComponent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
