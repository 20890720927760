import { useLazyQuery } from '@apollo/client';
import { BrandContentData, Spinner } from '@kalecard/common';
import { useContext, useEffect, useRef, useState } from 'react';
import { CONTENT } from '../../graphql/queries';
import ContentListComponent from '../lists/elements/ContentListComponent';
import { useIsVisible } from 'react-is-visible';
import { ContentContext } from '../../providers/ContentProvider';
import { UserContext } from '../../providers/UserProvider';
import { ContentActionType } from '../../reducers/ContentReducer';
import EmptyDataState from '../EmptyDataState';

export default function AllContent() {
  const { brandId } = useContext(UserContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const nodeRef = useRef(null);
  const isVisible = useIsVisible(nodeRef);

  const {
    state: {
      content,
      after,
      challengeId,
      shouldUpdateContent,
      startDate,
      endDate,
      selectedChallengeIds,
      creatorHandle,
    },
    dispatch: contentDispatch,
  } = useContext(ContentContext);
  const [getBrandContent] = useLazyQuery<BrandContentData>(CONTENT);

  const getContent = async () => {
    const result = await getBrandContent({
      variables: {
        brandId: brandId,
        after: after,
        challengeIds: challengeId ? [challengeId] : selectedChallengeIds,
        startDate: startDate?.toString(),
        endDate: endDate?.toString(),
        creatorHandle: creatorHandle,
      },
    });
    return result.data;
  };

  const getMoreContent = async () => {
    setLoadingMore(true);
    await getContent().then((data) => {
      contentDispatch({
        type: ContentActionType.UPDATE_CONTENT,
        payload: {
          content: data.content.edges.map((edge) => edge.node),
          hasNextPage: data.content.pageInfo.hasNextPage,
        },
      });
    });
    setLoadingMore(false);
  };

  const updateContent = async () => {
    setLoading(true);
    await getContent().then((data) => {
      contentDispatch({
        type: ContentActionType.SET_CONTENT,
        payload: {
          content: data.content.edges.map((edge) => edge.node),
          hasNextPage: data.content.pageInfo.hasNextPage,
        },
      });
    });
    setLoading(false);
  };

  useEffect(() => {
    if (isVisible && !loading) {
      getMoreContent();
    }
  }, [isVisible]);

  useEffect(() => {
    if (shouldUpdateContent === true) {
      updateContent();
    }
  }, [shouldUpdateContent]);

  return (
    <div className="pt-6 sm:px-6 md:px-8">
      <div>
        <ul
          role="list"
          className="flex flex-wrap justify-center md:justify-start"
        >
          {content.length > 0 &&
            !loading &&
            content.map((post, index) => (
              <ContentListComponent
                key={post.id + index}
                post={post}
                onContentWhitelistSubmit={null}
              />
            ))}
          <li ref={nodeRef}></li>
        </ul>
      </div>

      <div className="max-w-7xl">
        {content.length == 0 && !loading && !loadingMore && (
          <EmptyDataState message="There is no content found. Check back soon or refresh the page to see new posts." />
        )}
        {(loadingMore || loading) && (
          <div className="flex justify-center">
            <div className="flex flex-wrap content-center">
              <Spinner size="h-6 w-6" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
