import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { UserContext } from '../providers/UserProvider';
import { BRAND_PHOTO, BRAND_PHOTOS } from '../graphql/queries';
import KaleBrandsHelmet from '../components/utils/KaleBrandsHelmet';
import { Text } from '../components/catalyst/text';
import { Heading } from '../components/catalyst/heading';
import { InstagramLogo, Loading, Modal, TiktokLogo } from '@kalecard/common';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Route, Switch, useHistory } from 'react-router';
import PhotoPayment from '../components/photos/PhotoPayment';
import { useStatsigClient } from '@statsig/react-bindings';
import { Creator, PhotoMedia } from '../__generated__/graphql';
import { NavLink } from 'react-router-dom';
import {
  ArrowsPointingOutIcon,
  ArrowDownTrayIcon,
  CheckIcon,
  PlusIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { isDev } from '../config/betaAllowLists';

const PHOTO_PAGE_TABS = [
  { name: 'Available', href: `/photos`, current: true },
  { name: 'Purchased', href: `/purchasedPhotos`, current: false },
];

// This is the breakpoints for the number of columns in the masonry layout
// [x, y, z, ...] means that the layout will have 1 column at x, 2 columns at y, 3 columns at z, etc.
const PHOTO_VIEWER_COLUMN_COUNT_BREAKPOINTS = [350, 900, 1300];

// This turns [x, y, z, ...] into { x: 1, y: 2, z: 3, ... } for the masonry layout library we are using
const getMasonryLayoutColumns = () => {
  return PHOTO_VIEWER_COLUMN_COUNT_BREAKPOINTS.reduce(
    (acc, breakpoint, currentIndex) => {
      acc[breakpoint] = currentIndex + 1;
      return acc;
    },
    {}
  );
};

// This photo max width does not factor in the padding from the sidebar, photo container, etc. we are
// considering that negligiglbe and its ok if the photos are slightly higher
// resolution than they need to be
const getPhotoMaxWidthBasedOnScreenWidth = () => {
  const totalWidth = window.innerWidth;
  const numPhotosPerRow = PHOTO_VIEWER_COLUMN_COUNT_BREAKPOINTS.reduce(
    (acc, breakpoint) => {
      if (totalWidth > breakpoint) {
        return acc + 1;
      }
      return acc;
    },
    0
  );
  return Math.floor(totalWidth / numPhotosPerRow);
};

export default function PhotoPage() {
  return (
    <>
      <KaleBrandsHelmet pageName="Photos" />
      <div className="px-8">
        <div className="flex flex-col justify-between space-y-6 pb-8">
          <Heading>Photos</Heading>
          <PhotoInstructions />
        </div>
        <div className="border-b border-gray-200">
          <div className="block">
            <nav className="-mb-px flex space-x-8">
              {PHOTO_PAGE_TABS.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={(isActive) =>
                    (isActive
                      ? 'border-b-2 border-black text-black '
                      : 'text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600') +
                    'whitespace-nowrap px-1 pb-4 text-sm font-medium'
                  }
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
        <div>
          <Switch>
            <Route path="/photos">
              <PhotoViewer showPurchased={false} />
            </Route>
            <Route path="/purchasedPhotos">
              <PhotoViewer showPurchased={true} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

const PHOTO_INSTRUCTIONS_HOW_IT_WORKS_DISMISSED_KEY =
  'photo_instructions_how_it_works_dismissed';

const PhotoInstructions = () => {
  const hasBeenDismissed = localStorage.getItem(
    PHOTO_INSTRUCTIONS_HOW_IT_WORKS_DISMISSED_KEY
  );
  const [showHowItWorks, setShowHowItWorks] = useState(
    hasBeenDismissed == null
  );
  return (
    <div className="space-y-4">
      <p className="text-lg font-semibold text-gray-800">
        Welcome to your Kale Photo Feed - a curated queue of creator submitted
        photo content that are ready for purchase and use.
        {!showHowItWorks && (
          <button
            className="ml-2 text-blue-500 underline hover:text-blue-700"
            onClick={() => {
              setShowHowItWorks(true);
            }}
          >
            Learn More
          </button>
        )}
      </p>
      {showHowItWorks && (
        <div className="rounded-lg bg-[#D6F3FF] p-4">
          <div className="absolute right-12">
            <button
              className="text-gray-500 hover:text-gray-700"
              onClick={() => {
                setShowHowItWorks(false);
                localStorage.setItem(
                  PHOTO_INSTRUCTIONS_HOW_IT_WORKS_DISMISSED_KEY,
                  new Date().toISOString()
                );
              }}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <p className="text-lg font-semibold text-black">How It Works</p>
          <div className="px-4">
            <p className="text-base text-black">
              1. Browse the feed of creator-submitted photos.
            </p>
            <p className="text-base text-black">
              2. Click on an image to view it in greater detail.
            </p>
            <p className="text-base text-black">
              3. Click the plus icon to add photos to your cart, then click the
              cart icon at the top right to proceed to checkout.
            </p>
            <p className="text-base text-black">
              4. Complete purchase and receive your high-resolution photos
              without watermarks.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const PhotoViewer = ({ showPurchased }: { showPurchased: boolean }) => {
  const { client } = useStatsigClient();
  const { brandId } = useContext(UserContext);
  const { data, loading } = useQuery(BRAND_PHOTOS, {
    variables: {
      brandId: brandId,
      showPurchased: showPurchased,
      width: getPhotoMaxWidthBasedOnScreenWidth(),
    },
    // This is to ensure that the user sees the most up-to-date data when they navigate between the tabs
    fetchPolicy: 'network-only',
  });

  const [showExpandPhotoModal, setShowExpandPhotoModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [currentExpandPhoto, setCurrentExpandPhoto] =
    useState<PhotoMedia | null>(null);
  const [selectedPhotos, setSelectedPhotos] = useState<PhotoMedia[]>([]);

  if (!(client.checkGate('brand_purchased_photo') || isDev)) {
    return <></>;
  }

  const togglePhotoSelection = (photoMedia: PhotoMedia) => {
    if (selectedPhotos.includes(photoMedia)) {
      setSelectedPhotos(
        selectedPhotos.filter((photo) => photo.id !== photoMedia.id)
      );
    } else {
      setSelectedPhotos([...selectedPhotos, photoMedia]);
    }
  };

  return (
    <>
      <div className="px-4">
        <div className="space-y-3 pt-4">
          {loading && <Loading />}
          {data?.brandPhotos?.edges.length === 0 ? (
            <div className="flex justify-center pt-2">
              <p className="text-gray-500">
                {showPurchased
                  ? 'Your purchased photos will appear here.'
                  : 'No photos available. Check back later.'}
              </p>
            </div>
          ) : (
            <ResponsiveMasonry
              columnsCountBreakPoints={getMasonryLayoutColumns()}
            >
              <Masonry>
                {data?.brandPhotos?.edges.map((photoMediaEdge) => (
                  <div
                    key={photoMediaEdge?.node?.id}
                    className="relative m-2 overflow-hidden rounded-lg bg-white shadow-md"
                  >
                    <div className="space-y-1 bg-kale-mint-400 p-4">
                      <div
                        onClick={() => {
                          setCurrentExpandPhoto(
                            photoMediaEdge.node as PhotoMedia
                          );
                          setShowExpandPhotoModal(true);
                        }}
                      >
                        <ImageWithLoadingBackground
                          src={photoMediaEdge.node.mediaUrl.url}
                          alt="Brand Photo"
                          className="w-full rounded"
                        />
                      </div>
                      <CreatorDetails
                        creator={photoMediaEdge.node.deal.creator as Creator}
                      />
                      <Text>
                        {new Date(
                          photoMediaEdge.node.createdAt
                        ).toLocaleDateString()}
                      </Text>
                    </div>
                    <div className="absolute bottom-4 right-3">
                      {showPurchased ? (
                        <div className="flex space-x-2">
                          <DownloadPhotoButton
                            photoMedia={photoMediaEdge.node as PhotoMedia}
                            brandId={brandId}
                          />
                          <ExpandPhotoButton
                            onClick={() => {
                              setCurrentExpandPhoto(
                                photoMediaEdge.node as PhotoMedia
                              );
                              setShowExpandPhotoModal(true);
                            }}
                          />
                        </div>
                      ) : (
                        <AddToCartButton
                          photoMedia={photoMediaEdge.node as PhotoMedia}
                          isInCart={selectedPhotos.includes(
                            photoMediaEdge.node as PhotoMedia
                          )}
                          togglePhotoSelection={togglePhotoSelection}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </div>
      <Modal
        isModalOpen={showExpandPhotoModal}
        onClose={() => {
          setShowExpandPhotoModal(false);
        }}
        className="max-w-4xl p-4"
      >
        {currentExpandPhoto && (
          <ExpandPhotoModal
            photoMedia={currentExpandPhoto as PhotoMedia}
            brandId={brandId}
          />
        )}
      </Modal>

      <Modal
        isModalOpen={showCheckoutModal}
        onClose={() => {
          setShowCheckoutModal(false);
        }}
        className="w-1/2 p-4"
      >
        {selectedPhotos.length > 0 && (
          <CheckoutModal
            photoMedias={selectedPhotos}
            brandId={brandId}
            onComplete={() => {
              setShowCheckoutModal(false);
              setSelectedPhotos([]);
            }}
          />
        )}
      </Modal>
      {!showPurchased && selectedPhotos?.length > 0 && (
        <div className="fixed right-8 top-8">
          <button
            className="relative flex items-center justify-center rounded-lg bg-kale-green-500 p-4 text-white shadow-lg"
            onClick={() => {
              setShowCheckoutModal(true);
            }}
          >
            <ShoppingCartIcon className="h-8 w-8" />
            {selectedPhotos.length > 0 && (
              <span className="absolute -bottom-2 -right-2 flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                {selectedPhotos.length}
              </span>
            )}
          </button>
        </div>
      )}
    </>
  );
};

const AddToCartButton = ({
  photoMedia,
  isInCart,
  togglePhotoSelection,
}: {
  photoMedia: PhotoMedia;
  isInCart: boolean;
  togglePhotoSelection: (photoMedia: PhotoMedia) => void;
}) => {
  return (
    <button
      className={`rounded-full p-2 text-white ${
        isInCart ? 'bg-kale-green-400' : 'bg-kale-green-500'
      }`}
      onClick={() => {
        togglePhotoSelection(photoMedia);
      }}
    >
      {isInCart ? (
        <CheckIcon className="h-5 w-5" />
      ) : (
        <PlusIcon className="h-5 w-5" />
      )}
    </button>
  );
};

const ExpandPhotoButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="rounded-full bg-kale-green-500 p-2 text-white"
      onClick={onClick}
    >
      <ArrowsPointingOutIcon className="h-5 w-5" />
    </button>
  );
};

const DownloadPhotoButton = ({
  photoMedia,
  brandId,
}: {
  photoMedia: PhotoMedia;
  brandId: string;
}) => {
  const [brandPhoto] = useLazyQuery(BRAND_PHOTO);
  return (
    <button
      className="rounded-full bg-kale-green-500 p-2 text-white"
      onClick={async () => {
        const brandPhotoResponse = await brandPhoto({
          variables: {
            brandId: brandId,
            photoId: photoMedia.id,
          },
        });
        const url = brandPhotoResponse?.data?.brandPhoto?.mediaUrl?.url;

        // Download the photo if the url is available
        if (url) {
          window.open(url, '_blank');
        } else {
          // Show an error message if the photo is not available
          alert('Error downloading photo');
        }
      }}
    >
      <ArrowDownTrayIcon className="h-5 w-5" />
    </button>
  );
};

const ExpandPhotoModal = ({
  photoMedia,
  brandId,
}: {
  photoMedia: PhotoMedia;
  brandId: string;
}) => {
  const { data, loading } = useQuery(BRAND_PHOTO, {
    variables: {
      brandId: brandId,
      photoId: photoMedia.id,
    },
  });
  return (
    <div className="p-6">
      {loading ? (
        <Loading />
      ) : (
        <ImageWithLoadingBackground
          src={data?.brandPhoto?.mediaUrl.url}
          alt="Brand Photo"
          className="w-full rounded"
        />
      )}
    </div>
  );
};

const CheckoutModal = ({
  photoMedias,
  brandId,
  onComplete,
}: {
  photoMedias: PhotoMedia[];
  brandId: string;
  onComplete: () => void;
}) => {
  const history = useHistory();
  const columnsCount = photoMedias.length > 3 ? 3 : photoMedias.length;
  return (
    <div className="p-4">
      <div className="flex flex-col items-center space-y-8">
        <Masonry columnsCount={columnsCount}>
          {photoMedias.map((photoMedia) => (
            <div
              key={photoMedia.id}
              className="m-2 shadow-md"
            >
              <ImageWithLoadingBackground
                src={photoMedia.mediaUrl.url}
                alt="Brand Photo"
                className="w-full rounded"
              />
            </div>
          ))}
        </Masonry>
        <PhotoPayment
          brandId={brandId}
          photoIds={photoMedias.map((photoMedia) => photoMedia.id)}
          handleCheckout={(brandId, photoId) => {
            // Go to purchased photos
            onComplete();
            history.push('/purchasedPhotos');
          }}
        />
      </div>
    </div>
  );
};

const CreatorDetails = ({ creator }: { creator: Creator }) => {
  const instagramHandle = creator.externalUsers?.find(
    (externalUser) => externalUser.externalPlatform === 'INSTAGRAM'
  )?.username;
  const tiktokHandle = creator.externalUsers?.find(
    (externalUser) => externalUser.externalPlatform === 'TIKTOK'
  )?.username;
  const fullName = creator.name;

  return (
    <div className="flex flex-col space-y-1">
      {creator.name && <Text>{fullName}</Text>}
      {instagramHandle && (
        <a
          href={`https://www.instagram.com/${instagramHandle}`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1"
        >
          <InstagramLogo />
          <span>{instagramHandle}</span>
        </a>
      )}
      {tiktokHandle && (
        <a
          href={`https://www.tiktok.com/@${tiktokHandle}`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1"
        >
          <TiktokLogo />
          <span>{tiktokHandle}</span>
        </a>
      )}
    </div>
  );
};

const ImageWithLoadingBackground = ({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className: string;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className={`${imageLoaded ? '' : 'min-h-8 min-w-8'}`}>
      {!imageLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loading />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`${className} relative`}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};
