import { Challenge, Post } from '@kalecard/common';

export interface ContentState {
  content: Post[];
  hasNextPage?: boolean;
  after?: string;
  shouldUpdateContent?: boolean;
  challengeId?: string;
  selectableChallenges?: Challenge[];
  startDate?: string;
  endDate?: string;
  selectedChallengeIds?: string[];
  creatorHandle?: string;
}

export enum ContentActionType {
  SET_CONTENT,
  UPDATE_CONTENT,
  ADD_CHALLENGE_FILTER,
  REMOVE_CHALLENGE_FILTER,
  SET_DATE_FILTERS,
  TOGGLE_CHALLENGE_FILTER,
  SET_CREATOR_HANDLE_FILTER,
}

export interface ContentAction {
  type: ContentActionType;
  payload: ContentState;
}

export function ContentReducer(
  state: ContentState,
  action: ContentAction
): ContentState {
  switch (action.type) {
    case ContentActionType.SET_CONTENT: {
      const { content, hasNextPage } = action.payload;
      return {
        ...state,
        content: content,
        hasNextPage: hasNextPage,
        after: content.length.toString(),
        shouldUpdateContent: false,
      };
    }

    case ContentActionType.UPDATE_CONTENT: {
      const { content, hasNextPage } = action.payload;

      const updatedContent = [...state.content, ...content];
      return {
        ...state,
        content: updatedContent,
        hasNextPage: hasNextPage,
        after: updatedContent.length.toString(),
        shouldUpdateContent: false,
      };
    }

    case ContentActionType.ADD_CHALLENGE_FILTER: {
      const { challengeId } = action.payload;

      return {
        ...state,
        challengeId: challengeId,
        after: '0',
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.REMOVE_CHALLENGE_FILTER: {
      return {
        ...state,
        challengeId: null,
        after: '0',
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.SET_DATE_FILTERS: {
      const { startDate, endDate } = action.payload;

      return {
        ...state,
        startDate: startDate,
        endDate: endDate,
        after: '0',
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.TOGGLE_CHALLENGE_FILTER: {
      const { challengeId } = action.payload;

      const existingChallenge = state.selectedChallengeIds?.find(
        (id) => id === challengeId
      );
      const updatedChallenges = existingChallenge
        ? state.selectedChallengeIds?.filter((id) => id !== challengeId)
        : state.selectedChallengeIds
        ? [...state.selectedChallengeIds, challengeId]
        : [challengeId];

      return {
        ...state,
        selectedChallengeIds: updatedChallenges,
        after: '0',
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.SET_CREATOR_HANDLE_FILTER: {
      const { creatorHandle } = action.payload;

      return {
        ...state,
        creatorHandle: creatorHandle,
        after: '0',
        shouldUpdateContent: true,
      };
    }
  }
}
