import { useQuery } from '@apollo/client';
import { SIMPLE_EXTERNAL_POSTS } from '../graphql/queries';
import { useParams } from 'react-router';
import { ExternalPostComponent } from '../components/settings/instagram/ExternalPostComponent';
import { ExternalPost } from '../__generated__/graphql';
import { Spinner } from '@kalecard/common';

export function ExternalUserPosts() {
  const params = useParams();
  const externalUserId = params['id'];
  const { data, loading } = useQuery(SIMPLE_EXTERNAL_POSTS, {
    variables: { externalUserId },
  });

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Instagram Media
        </h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="mb-4 flex flex-col items-center">
          <div className="mb-2 w-full overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Caption
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Insights
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.externalPosts.edges.map((externalPost) => (
                      <ExternalPostComponent
                        key={externalPost.node.id}
                        externalPost={externalPost.node as ExternalPost}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            {loading ? (
              <div className="flex flex-wrap content-center">
                <Spinner size="h-6 w-6" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
