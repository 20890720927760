import { ExternalPost } from '../../../__generated__/graphql';
import { timeDifferenceForDate } from '@kalecard/common';

export function ExternalPostComponent({
  externalPost,
}: {
  externalPost: ExternalPost;
}) {
  return (
    <tr>
      <td className="max-w-xs truncate px-6 py-4">
        <a
          className="truncate text-sm font-semibold text-indigo-600 hover:text-indigo-900"
          href={externalPost.externalPermalink}
          target="_blank"
          rel="noreferrer"
        >
          {externalPost.caption ? externalPost.caption : '(empty caption)'}
        </a>
        <div className="text-sm text-gray-500">
          {timeDifferenceForDate(Number(externalPost.createdAt))} -{' '}
          <a
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={externalPost.mediaUrl}
            target="_blank"
            rel="noreferrer"
          >
            View media
          </a>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div>{externalPost.mediaType}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <span>Likes: {externalPost.likeCount}</span>
          <span>Comments: {externalPost.commentCount}</span>
        </div>
      </td>
    </tr>
  );
}
