import { useLazyQuery, useQuery } from '@apollo/client';
import {
  BrandTransaction,
  BrandTransactionData,
  Spinner,
  numberWithCommas,
  prettyDecimal,
} from '@kalecard/common';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  BRAND_TRANSACTIONS,
  GET_STRIPE_HOSTED_INVOICE_URL,
} from '../graphql/queries';
import { classNames } from '../utils/style';
import { UserContext } from '../providers/UserProvider';
import EmptyDataState from './EmptyDataState';

interface BillingHistoryState {
  months: {
    date: string;
    transactions: BrandTransaction[];
  }[];
}

export default function PaymentHistoryList() {
  const { brandId } = useContext(UserContext);
  const [edges, setEdges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [billingHistory, setBillingHistory] = useState<BillingHistoryState>();
  const [after, setAfter] = useState(null);

  const onQueryComplete = (data: BrandTransactionData) => {
    const newTransactions = data.brandTransactions.edges.map(
      (edge) => edge.node
    );
    setBillingHistory(formatTransactions(newTransactions));
    setEdges(data.brandTransactions.edges);
    setAfter(
      data.brandTransactions.edges[data.brandTransactions.edges.length - 1]
        ?.cursor
    );
    setLoading(false);
  };

  const { data, fetchMore } = useQuery<BrandTransactionData>(
    BRAND_TRANSACTIONS,
    {
      variables: {
        brandId: brandId,
        first: 20,
        after: null,
      },
      onCompleted: (data) => onQueryComplete(data),
    }
  );

  const loadMore = () => {
    setLoading(true);
    fetchMore({
      variables: {
        brandId: brandId,
        first: 20,
        after: after,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const previousEdges = prev?.brandTransactions.edges;
        const fetchMoreEdges = fetchMoreResult?.brandTransactions.edges;

        fetchMoreResult.brandTransactions.edges = [
          ...previousEdges,
          ...fetchMoreEdges,
        ];
        fetchMoreResult.brandTransactions.pageInfo =
          fetchMoreResult.brandTransactions.pageInfo;

        onQueryComplete(fetchMoreResult);
        return fetchMoreResult;
      },
    });
    setLoading(false);
  };

  return (
    <div>
      {edges.length > 0 && (
        <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
          <div className="overflow-hidden rounded-lg border border-t border-gray-200">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <table className="w-full text-left">
                  <thead className="sr-only">
                    <tr>
                      <th>Transaction type</th>
                      <th>Amount</th>
                      <th>More details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingHistory?.months.map((month) => (
                      <Fragment key={month.date}>
                        <tr className="text-sm leading-6 text-gray-900">
                          <th
                            scope="colgroup"
                            colSpan={3}
                            className="relative isolate py-2 font-semibold"
                          >
                            <time dateTime={month.date}>{month.date}</time>
                            <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-t border-gray-200 bg-gray-100" />
                            <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-t border-gray-200 bg-gray-100" />
                          </th>
                        </tr>
                        {month.transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className="relative py-5 pr-6">
                              <div className="flex gap-x-6">
                                <div className="flex-auto">
                                  <div className="flex items-start gap-x-3">
                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                      {transaction.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>

                            <td className="relative py-5 pr-6">
                              <div className="flex gap-x-6">
                                <div className="flex-auto">
                                  <div className="flex items-start gap-x-3">
                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                      ${prettyDecimal(transaction.amount)}
                                    </div>
                                    <TransactionStatus
                                      brandTransaction={transaction}
                                    />
                                  </div>
                                  {transaction.paymentMethodType ===
                                  'CONTENT_BUDGET' ? (
                                    <div className="mt-1 text-xs leading-5 text-gray-500">
                                      Paid from content budget.
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>
                            <td className="py-5 text-right">
                              {transaction.invoice && (
                                <>
                                  <div className="flex justify-end">
                                    <InvoiceUrl
                                      brandId={brandId}
                                      brandTransaction={transaction}
                                    />
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            {loading ? (
              <div className="flex flex-wrap content-center">
                <Spinner size="h-6 w-6" />
              </div>
            ) : (
              data?.brandTransactions.pageInfo?.hasNextPage && (
                <button
                  className="mb-2 inline-flex w-1/6 items-center justify-center rounded-[28px] bg-green-100 px-4 py-3 text-center text-sm font-medium text-black shadow hover:opacity-60"
                  onClick={loadMore}
                >
                  Load more
                </button>
              )
            )}
          </div>
        </div>
      )}
      {edges.length === 0 && !loading && (
        <div className="sm:px-6 md:px-8">
          <EmptyDataState message="No payments have been made." />
        </div>
      )}
    </div>
  );
}

function InvoiceUrl({
  brandId,
  brandTransaction,
}: {
  brandId: string;
  brandTransaction: BrandTransaction;
}) {
  const [getInvoiceUrl, { loading }] = useLazyQuery(
    GET_STRIPE_HOSTED_INVOICE_URL
  );

  return (
    <div className="flex items-center space-x-2">
      {loading && <Spinner size="h-4 w-4" />}
      <button
        disabled={loading}
        onClick={async () => {
          // Get invoice url
          const result = await getInvoiceUrl({
            variables: {
              brandId: brandId,
              brandTransactionId: brandTransaction.id,
            },
            fetchPolicy: 'network-only',
          });
          // Open invoice url in new tab
          if (result.data.getStripeHostedInvoiceUrl === null) {
            alert(
              'There was an error loading your invoice. Please try again later.'
            );
          } else {
            window.open(result.data.getStripeHostedInvoiceUrl, '_blank');
          }
        }}
        className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
      >
        {getBrandTransactionStatus(brandTransaction) === 'Paid' ||
        getBrandTransactionStatus(brandTransaction) === 'Void'
          ? 'View'
          : 'Pay'}
        <span className="hidden sm:inline"> invoice</span>
      </button>
    </div>
  );
}

function TransactionStatus({
  brandTransaction,
}: {
  brandTransaction: BrandTransaction;
}) {
  const statuses = {
    Paid: 'text-green-700 bg-green-50 ring-green-600/20',
    'Kale Credit': 'text-green-700 bg-green-50 ring-green-600/20',
    Open: 'text-blue-600 bg-blue-50 ring-blue-500/10',
    Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
    Void: 'text-gray-700 bg-gray-50 ring-gray-600/10',
  };
  const [status, setStatus] = useState('');

  useEffect(() => {
    const newStatus = getBrandTransactionStatus(brandTransaction);
    setStatus(newStatus);
  }, [brandTransaction]);

  return (
    <div
      className={classNames(
        statuses[status],
        'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
      )}
    >
      {status}
    </div>
  );
}

function getBrandTransactionStatus(brandTransaction: BrandTransaction): string {
  if (brandTransaction.type === 'KALE_CREDIT') {
    return 'Kale Credit';
  } else if (
    brandTransaction?.invoice?.status === 'PAID' ||
    brandTransaction?.paymentMethodType === 'CREDIT_CARD' ||
    brandTransaction?.paymentMethodType === 'CONTENT_BUDGET' ||
    brandTransaction?.paymentStatus === 'COMPLETE'
  ) {
    return 'Paid';
  } else if (
    brandTransaction?.invoice?.status === 'SENT' &&
    hasMoreThanNDaysPassed(brandTransaction?.invoice?.sentAt, 30)
  ) {
    return 'Overdue';
  } else if (brandTransaction?.invoice?.status === 'VOID') {
    return 'Void';
  } else {
    return 'Open';
  }
}

function formatTransactions(transactions: BrandTransaction[]): BillingHistoryState {
  const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' });

  // Group transactions by month and year
  const groupedTransactions = transactions.reduce((acc, transaction) => {
    const monthYear = formatter.format(new Date(Number(transaction.createdAt)));
    (acc[monthYear] ||= []).push(transaction);
    return acc;
  }, {} as Record<string, BrandTransaction[]>);

  // Sort months and transactions within each month
  const sortedMonths = Object.entries(groupedTransactions)
    .map(([date, transactions]) => {
      const kaleCreditTransactions = transactions.filter(t => t.type === 'KALE_CREDIT');
      const otherTransactions = transactions
        .filter(t => t.type !== 'KALE_CREDIT')
        .sort((a, b) => Number(b.createdAt) - Number(a.createdAt));

      return { date, transactions: [...kaleCreditTransactions, ...otherTransactions] };
    });

  return { months: sortedMonths };
}

function hasMoreThanNDaysPassed(sendDate: string, n: number): boolean {
  const sendDateObj = new Date(Number(sendDate));
  const currentDate = new Date();

  const differenceInTime = currentDate.getTime() - sendDateObj.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays > n;
}
